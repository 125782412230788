import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { SpinnerInfinity } from 'spinners-react';

import ErrorPage from "./pages/error/Error";
import LandingPage from './pages/landing/Landing';
import ProfilePage from './pages/profile/Profile';
import ConfiguratorPage from './pages/configurator/Configurator';
import PrivacyPage from './pages/privacy/Privacy';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/@knipknap/:productid",
    element: <ConfiguratorPage />,
  },
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
]);

export const Router = (props) => (
  <RouterProvider
    router={router}
    fallbackElement={<SpinnerInfinity size={50} />}>
    {props.children}
  </RouterProvider>
);
