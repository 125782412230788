import React from 'react';
import PropTypes from 'prop-types';
import { useKeycloak } from '@react-keycloak/web'

import { Link } from "react-router-dom";
import { Button } from '../button/Button';
import './header.css';
import logo from './barebaric-logo.png';

export const Header = ({ user, onLogin, onLogout, onCreateAccount }) => {
  return (
    <header>
      <div className="header-left">
        <Link to="/"><img id="logo" src={logo} alt="" /></Link>
      </div>
      <div className="header-right">
        {user ? (
          <>
            <span className="welcome">
              Welcome, <Link to="/profile">{user.given_name}</Link>!
            </span>
            <Button onClick={onLogout} label="Log out" />
          </>
        ) : (
          <>
            <span className="welcome"></span>
            <Button onClick={onLogin} label="Log in" />
            <Button primary onClick={onCreateAccount} label="Sign up" />
          </>
        )}
      </div>
    </header>
  );
};

export const AutoHeader = (props) => {
  const { keycloak } = useKeycloak()

  return (
    <Header
      user={keycloak.idTokenParsed}
      onLogin={() => keycloak.login()}
      onLogout={() => keycloak.logout()}
      onCreateAccount={() => keycloak.register()}
      {...props}
    />
  )
}

Header.propTypes = {
  user: PropTypes.shape({}),
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
};

Header.defaultProps = {
  user: null,
};
