import React from 'react';
import PropTypes from 'prop-types';
import './socialbuttonbar.css';

import facebookIcon from './facebook.svg';
import githubIcon from './github.svg';
import instagramIcon from './instagram.svg';
import pinterestIcon from './pinterest.svg';
import printablesIcon from './printables.svg';
import storeIcon from './store.svg';
import twitterIcon from './twitter.svg';
import webIcon from './web.svg';
import whatsappIcon from './whatsapp.svg';
import youtubeIcon from './youtube.svg';

export const SocialButtonBar = ({
	facebook,
	github,
	instagram,
	pinterest,
	printables,
	store,
	twitter,
	web,
	whatsapp,
	youtube,
	backgroundColor,
	...props }) => (
  <div className="socialbuttonbar"
      style={backgroundColor && { backgroundColor }}
      {...props}>
    {facebook &&
    <a className="social-icon" id="facebook" href={facebook}>
	<img src={facebookIcon} alt="Facebook" />
    </a>}
    {github &&
    <a className="social-icon" id="github" href={github}>
	<img src={githubIcon} alt="Github" />
    </a>}
    {instagram &&
    <a className="social-icon" id="instagram" href={instagram}>
	<img src={instagramIcon} alt="Instagram" />
    </a>}
    {pinterest &&
    <a className="social-icon" id="pinterest" href={pinterest}>
	<img src={pinterestIcon} alt="Pinterest" />
    </a>}
    {printables &&
    <a className="social-icon" id="printables" href={printables}>
	<img src={printablesIcon} alt="Printables" />
    </a>}
    {store &&
    <a className="social-icon" id="store" href={store}>
	<img src={storeIcon} alt="Shop" />
    </a>}
    {twitter &&
    <a className="social-icon" id="twitter" href={twitter}>
	<img src={twitterIcon} alt="Twitter" />
    </a>}
    {web &&
    <a className="social-icon" id="web" href={web}>
	<img src={webIcon} alt="Web" />
    </a>}
    {whatsapp &&
    <a className="social-icon" id="whatsapp" href={whatsapp}>
	<img src={whatsappIcon} alt="WhatsApp" />
    </a>}
    {youtube &&
    <a className="social-icon" id="youtube" href={youtube}>
	<img src={youtubeIcon} alt="YouTube" />
    </a>}
  </div>
);

SocialButtonBar.propTypes = {
  facebook: PropTypes.string,
  github: PropTypes.string,
  instagram: PropTypes.string,
  pinterest: PropTypes.string,
  printables: PropTypes.string,
  store: PropTypes.string,
  twitter: PropTypes.string,
  web: PropTypes.string,
  whatsapp: PropTypes.string,
  youtube: PropTypes.string,
  backgroundColor: PropTypes.string,
};

SocialButtonBar.defaultProps = {
  facebook: undefined,
  github: undefined,
  instagram: undefined,
  pinterest: undefined,
  printables: undefined,
  store: undefined,
  twitter: undefined,
  web: undefined,
  whatsapp: undefined,
  youtube: undefined,
  backgroundColor: null,
};
