import React from 'react';

import { AutoHeader } from '../../components/header/Header';
import { ModelViewer } from '../../components/modelviewer/ModelViewer';
import { Footer } from '../../components/footer/Footer';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import './configurator.css';

export default function ConfiguratorPage() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1280, min: 1100 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 1100, min: 0 },
      items: 1
    }
  };

  return (
    <div className="configurator">
      <AutoHeader/>

      <div className="content">
        <div className="title">
	  <h1>Fibell</h1>
	  <span>A nice product.</span>
        </div>
        <div className="gallery">
          <Carousel
            containerClass="carousel"
            draggable={false}
            swipeable={false}
            responsive={responsive}>
            <div className="slide">
              <ModelViewer modelUrl={process.env.PUBLIC_URL + "/model1.glb"} />
            </div>
            <div className="slide">
              <ModelViewer modelUrl={process.env.PUBLIC_URL + "/model2.glb"} />
            </div>
          </Carousel>
        </div>

        <div className="section">
	  <div className="section-text">
	    <h2>Description</h2>
	    <span>The enclosure is first modeled in FreeCAD, then sliced in Cura,
and 3D printed. It is then sanded, primed, and spray painted for
a clean finish.</span>
          </div>
          <div className="section-image">
	    <img src="" alt=""/>
          </div>
        </div>

        <div className="section">
	  <div className="section-text">
	    <h2>Electronics</h2>
	    <span>The electronics are fairly simple: Just a FPC1020A fingerprint
reader attached to a standard ESP32 for Wifi connectivity.</span>
          </div>
          <div className="section-image">
	    <img src="" alt=""/>
          </div>
        </div>
      </div>
  
      <Footer/>
    </div>
  );
};
