import { AutoHeader } from '../../components/header/Header';
import { Footer } from '../../components/footer/Footer';

import './privacy.css';

export default function PrivacyPage() {
  return (
    <div className="privacy">
      <AutoHeader/>

      <div className="content">
        <div className="section">
          <h1>Privacy Policy</h1>
        </div>

        <div className="section">
          <div className="section-text">
            <h2>Scope</h2>
            <span>
This privacy policy informs users about the type, extent, and purpose of the collection and use of personal data by the responsible provider Barebaric. Barebaric is represented by <i>Samuel Abels, Box 03536, Ehrenbergstrasse 16a, 10245 Berlin, Germany</i> on this website, hereinafter referred to as the &quot;offer&quot;.

The legal foundations of data protection can be found in the Federal Data Protection Act (BDSG) and the Telemedia Act (TMG).
            </span>
          </div>

          <div className="section-text">
            <h2>Access data / server log files</h2>
            <span>
The provider (or its webspace provider) collects data about every access to the offer (so-called server log files). Access data includes:

Name of the accessed website, file, date and time of access, data volume transmitted, message about successful access, browser type and version, user&apos;s operating system, referrer URL (the previously visited page), IP address and the requesting provider.

The provider only uses the log data for statistical evaluations for the purpose of operating, security, and optimizing the offer. However, the provider reserves the right to check the log data retrospectively if there is a justified suspicion of illegal use based on concrete indications.
            </span>
          </div>

          <div className="section-text">
            <h2>Handling of personal data</h2>
            <span>
Personal data is information with which a person can be determined, that is, information that can be traced back to a person. This includes the name, email address or telephone number. But also data about preferences, hobbies, memberships or which websites have been viewed by someone also count as personal data.

Personal data is only collected, used, and passed on by the provider if it is legally allowed or the users consent to the data collection.
            </span>
          </div>

          <div className="section-text">
            <h2>Contact</h2>
            <span>
When contacting the provider (for example via contact form or email), the user&apos;s information will be stored for the purpose of processing the request and in case follow-up questions arise.
            </span>
          </div>

          <div className="section-text">
            <h2>Cookies</h2>
            <span>
Cookies are small files that enable specific device-related information to be stored on the user&apos;s access device (PC, smartphone, etc.). They serve one purpose to improve the user-friendliness of websites and thus users (e.g. storage of login data). On the other hand, they serve to collect statistical data on the use of websites and to analyze it for the purpose of improving the offer. Users can influence the use of cookies. Most browsers have an option that restricts or completely prevents the storage of cookies. However, it should be noted that the use and especially the comfort of use will be limited without cookies.

You can manage many online advertising cookies from companies through the US site http://www.aboutads.info/choices/ or the EU site http://www.youronlinechoices.com/uk/your-ad-choices/.
            </span>
          </div>

          <div className="section-text">
            <h2>Registration function and saved data</h2>
            <span>
The data entered during registration and the user profile data will be stored by the provider. The provider will also store the data entered in the context of the use of the offer, such as content and contributions. The data will only be passed on to third parties if this is necessary for the provision of the offer or if the user has previously consented. The user has the right to request information about the stored data at any time and to request correction or deletion of the data.
The data collected are your first and last name, your email address, your IP address, and if you use login via Google, Facebook, or Twitter, the connection to the account with the respective provider. Additionally, this includes the data you entered into the system beyond that.
            </span>
          </div>

          <div className="section-text">
            <h2>Google Analytics</h2>
            <span>
This website uses Google Analytics, a web analysis service provided by Google Inc. (&quot;Google&quot;). Google Analytics uses so-called &quot;cookies,&quot; text files that are stored on users&apos; computers and allow for an analysis of website usage by users. The information generated by the cookie about users&apos; use of this website is usually transmitted to a Google server in the United States and stored there.

In the event that IP anonymization is activated on this website, Google will shorten the IP address of users within member states of the European Union or in other states that are parties to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the US and shortened there. IP anonymization is active on this website. On behalf of the operator of this website, Google will use this information to evaluate users&apos; use of the website, compile reports on website activity, and provide other services related to website use and internet use to the website operator.

The IP address transmitted by your browser as part of Google Analytics will not be combined with any other data from Google. Users can prevent the storage of cookies by adjusting their browser software; this website does warn users, however, that in this case they may not be able to use all features of the website to their full extent. Users can also prevent Google from collecting the data generated by the cookie and related to their use of the website (including their IP address) and processing this data by downloading and installing the browser plugin available at the following link: http://tools.google.com/dlpage/gaoptout?hl=de.

Alternatively, for browsers on mobile devices or as an alternative to the browser add-on, click this link to prevent Google Analytics from collecting data on this website in the future. This will place an opt-out cookie on your device. If you delete your cookies, you will need to click this link again.
            </span>
          </div>

          <div className="section-text">
            <h2>Revocation, Changes, Corrections, and Updates</h2>
            <span>
The user has the right to request free information about the personal data stored about them. Additionally, the user has the right to correct inaccurate data, block and delete their personal data, unless there is a legal obligation to retain it.
            </span>
          </div>
        </div>
      </div>
  
      <Footer/>
    </div>
  );
};
