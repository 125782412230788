import React from 'react';

import { AutoHeader } from '../../components/header/Header';
import { DropArea } from '../../components/droparea/DropArea';
import { Footer } from '../../components/footer/Footer';

import './landing.css';

export default function LandingPage() {
  return (
    <div className="landing">
      <AutoHeader/>

      <div className="content">
        <div className="upper">
          <div className="middle">
            <h1>Upload your FreeCAD model to view it online.</h1>
            <DropArea
              name="File"
              className="dropArea"
              placeholder="Enter a URL to a FreeCAD model" />
          </div>
        </div>
      </div>
  
      <Footer/>
    </div>
  );
};
