import { SocialButtonBar } from '../socialbuttonbar/SocialButtonBar';
import './footer.css';

export const Footer = () => (
  <div className="footer">
    <SocialButtonBar
      youtube="https://youtube.com/@barebaric"
      github="https://github.com/barebaric"
      instagram="https://www.instagram.com/barebaric1/"
      pinterest="https://www.pinterest.de/barebaric/"
      printables="https://www.printables.com/@barebaric/"
      store="https://barebaric.com" />
    <div className="links">
      <a href="/privacy">Privacy policy</a>
    </div>
  </div>
);
