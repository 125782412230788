import React, { useState } from 'react';
import Uppy from "@uppy/core";
import XHR from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/react';

import '@uppy/core/dist/style.min.css';
import "@uppy/dashboard/dist/style.min.css";
import './droparea.css';
//import "@uppy/drag-drop/dist/style.min.css";

export const DropArea = ({
	className,
	...props
}) => {
const [uppy] = useState(() => new Uppy({
  autoProceed: true,
  allowMultipleUploadBatches: false,
  restrictions: {
    maxFileSize: 1000000,
    maxNumberOfFiles: 1,
    minNumberOfFiles: 1,
    allowedFileTypes: ['.FCStd']
  }
}).use(XHR, {
  endpoint: 'https://fcexport.barebaric.com/'
}));

uppy.on("complete", result => {
  console.log("Upload complete! We’ve uploaded these files:");
  console.log(result.successful);
});

uppy.on("file-added", file => {
  console.log(file);
});

uppy.on("upload", data => {
  console.log(data);
});

  return (
    <Dashboard
      inline={true}
      allowedFileTypes={['.FCStd']}
      showSelectedFiles={false}
      proudlyDisplayPoweredByUppy={false}
      uppy={uppy}
      note="You can drag one model with a size of up to 2MB"
      disableThumbnailGenerator={true}
      //plugins={["GoogleDrive"]}
      className={className + ' droparea'}
      {...props}/>
  );
};
