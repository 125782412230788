import { useRouteError } from "react-router-dom";
import './error.css';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p class="zoom-area">Sorry, an unexpected error has occurred.</p>
      <p class="zoom-area"><i>{error.statusText || error.message}</i></p>
        
      <section class="error-container">
        <span class="four"><span class="screen-reader-text">4</span></span>
        <span class="zero"><span class="screen-reader-text">0</span></span>
        <span class="four"><span class="screen-reader-text">4</span></span>
      </section>
      <div class="link-container">
        <a target="_blank" href="/" class="more-link">Go to the homepage</a>
      </div>
    </div>
  );
}
