import React, { Suspense } from "react";
import * as THREE from 'three';
import {PresentationControls, Stage} from '@react-three/drei';
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./modelviewer.css";

const MyModel = ({ modelUrl }) => {
  const { scene } = useLoader(GLTFLoader, modelUrl);

  return (
      <primitive object={scene} children-0-castShadow />
  );
}

export const ModelViewer = ({ modelUrl }) => {
  return (
    <Suspense fallback={null}>
      <Canvas
          gl={{
            physicallyCorrectLights: true,
            shadowMap: {
              enabled: true,
              type: THREE.PCFSoftShadowMap,
            },
          }}
	  fov={80}
          near={0.1}
          far={1000}
          position={[0, 2, 30]}
          shadows>
        <Stage
          preset='soft'
          shadows={{ type: 'contact', opacity: .4, offset: 0.06 }}
          adjustCamera={1.2}
          environment="city">
          <PresentationControls snap={true} polar={[-Infinity, Infinity]} rotation={[0, -0.3, 0]}>
            <MyModel modelUrl={modelUrl} />
          </PresentationControls>
        </Stage>
      </Canvas>
    </Suspense>
  );
};

export default ModelViewer;
