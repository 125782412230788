import React from 'react';
import { useKeycloak } from '@react-keycloak/web'

import { AutoHeader } from '../../components/header/Header';
import { Footer } from '../../components/footer/Footer';

import './profile.css';

export default function ProfilePage() {
  const { keycloak, initialized } = useKeycloak()

  var content = <div className="content">Unknown error</div>;

  if (!initialized)
    content = <div className="content">Loading...</div>;

  else if (!keycloak.authenticated)
    content = <div className="content">Not logged in</div>;

  else
    content = (
      <div className="content">
        <div className="section">
	  <h1>Profile</h1>
        </div>

        <div className="section">
          <div className="section-text">
            <h2>Account</h2>
            <ul>
              <li>
                  <label>User ID:</label>
                  <span className="value">{keycloak.tokenParsed.sub}</span>
              </li>
              <li>
                  <label>Name:</label>
                  <span className="value">{keycloak.tokenParsed.name}</span>
              </li>
              <li>
                  <label>Email:</label>
                  <span className="value">{keycloak.tokenParsed.email}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
  )

  return (
    <div className="profile">
      <AutoHeader/>

      {content}
  
      <Footer/>
    </div>
  );
};
